@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./Open_Sans/OpenSans-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Droid Sans';
  src: url('./Droid_Sans/DroidSansMono.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
