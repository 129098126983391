.toggle-switch {
  position: relative;
  width: 75px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid var(--border-grey);
    border-radius: var(--bdr-2);
    margin: 0;
    height: 32px;
    background-color: var(--toggle-switch-bg-color);
    position: relative;
    display: flex;
    align-items: center;
    &:after {
      position: absolute;
      content: '';
      opacity: 0;
      width: 100%;
      height: 100%;
      background-color: var(--toggle-switch-active-bg-color);
      transition: all 0.2s ease-in 0s;
    }
  }
  &-switch {
    display: block;
    width: 22px;
    height: 22px;
    background-color: var(--toggle-switch-knob-color);
    border-radius: var(--bdr-1);
    transition: all 0.2s ease-in 0s;
    margin: var(--space-2);
    z-index: 2;
  }
  &-checkbox:checked + &-label {
    border-color: var(--toggle-switch-active-border-color);
    &::after {
      opacity: 1;
      transition: all 0.2s ease-in 0s;
    }
    .toggle-switch-switch {
      transform: translateX(43px);
    }
  }
}
