.svg-icon {
  display: inline-block;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  width: var(--icon-1);
  height: var(--icon-1);
  background-color: var(--icon-color);
}

.icon-pen {
  mask-image: url('../../assets/icons/icon_pen.svg');
  margin-left: 0;
  align-self: center;
  justify-self: center;
  transition: background-color var(--hover-duration);
  &:hover {
    background-color: var(--icon-color-hover);
    transition: background-color var(--hover-duration);
  }
}

.icon-menu {
  mask-image: url('../../assets/icons/icon_menu.svg');
}

.icon-add-pt {
  mask-image: url('../../assets/icons/icon_add_pt.svg');
}

.icon-grip {
  mask-image: url('../../assets/icons/icon_grip.svg');
}

.icon-capslock {
  mask-image: url('../../assets/icons/icon_capslock.svg');
}

.icon-add-process {
  mask-image: url('../../assets/icons/icon_folder_plus_filled.svg');
}

.icon-info {
  mask-image: url('../../assets/icons/icon_info.svg');
}

.icon-users {
  mask-image: url('../../assets/icons/icon_users.svg');
  width: 18px;
}

.icon-bell {
  mask-image: url('../../assets/icons/icon_bell.svg');
}

.icon-caret {
  mask-image: url('../../assets/icons/icon_caret.svg');
}

.icon-exclamation {
  mask-image: url('../../assets/icons/icon_exclamation.svg');
}

.icon-exclamation-background {
  mask-image: url('../../assets/icons/icon_exclamation_background.svg');
}

.icon-help {
  mask-image: url('../../assets/icons/icon_fragezeichen.svg');
}

.icon-support-light {
  mask-image: url('../../assets/icons/icon_support_light.svg');
  transform: scale(1.2);
}

.icon-support-dark {
  mask-image: url('../../assets/icons/icon_support_dark.svg');
}

.icon-deactivate {
  mask-image: url('../../assets/icons/icon_deactivate.svg');
}

.icon-activate {
  mask-image: url('../../assets/icons/icon_activate.svg');
}

.icon-help-light {
  mask-image: url('../../assets/icons/icon_fragezeichen_light.svg');
}

.icon-duplicate {
  mask-image: url('../../assets/icons/icon_duplicate.svg');
}
.icon-duplicate.copy-icon {
  cursor: pointer;
  width: var(--icon-1);
  height: var(--icon-1);
  background-color: var(--icon-color);
  justify-self: end;
}
.icon-duplicate.copy-icon.table {
  margin-left: 8px;
  background-color: var(--icon-color);
  width: 12px;
  height: 12px;
}

.spinner {
  mask-image: url('../../assets/spinner.svg');
}

.icon-data-security {
  mask-image: url('../../assets/icons/icon_datenschutz.svg');
}

.icon-imprint {
  mask-image: url('../../assets/icons/icon_impressum.svg');
}

.icon-logout {
  mask-image: url('../../assets/icons/icon_logout.svg');
}

.icon-clock {
  mask-image: url('../../assets/icons/icon_clock.svg');
}

.icon-clock-reverse {
  mask-image: url('../../assets/icons/icon_clock_reverse.svg');
}

.icon-folder {
  mask-image: url('../../assets/icons/icon_folder_outline.svg');
}

.icon-invite {
  mask-image: url('../../assets/icons/icon_invited.svg');
}

.icon-download-file {
  mask-image: url('../../assets/icons/icon_download_file.svg');
}

.icon-trash {
  mask-image: url('../../assets/icons/icon_papierkorb.svg');
}

.icon-trash.custom-fields {
  width: 20px;
  height: 20px;
  background-color: var(--icon-color);
  mask-position: center;
}

.icon-delete {
  mask-image: url('../../assets/icons/icon_delete.svg');
}

.icon-broom {
  mask-image: url('../../assets/icons/icon_broom.svg');
}

.icon-unarchive {
  mask-image: url('../../assets/icons/icon_unarchive.svg');
}

.icon-add-test {
  mask-image: url('../../assets/icons/icon_add_test.svg');
}

.icon-settings {
  mask-image: url('../../assets/icons/icon_zahnrad.svg');
}

.icon-account {
  mask-image: url('../../assets/icons/icon_account.svg');
}

.icon-shop {
  mask-image: url('../../assets/icons/icon_shop.svg');
}

.icon-add {
  mask-image: url('../../assets/icons/icon_neu-plus.svg');
}

.icon-edit {
  mask-image: url('../../assets/icons/icon_edit.svg');
}

.icon-plus {
  mask-image: url('../../assets/icons/icon_plus.svg');
}

.icon-money-bag {
  mask-image: url('../../assets/icons/icon_money_bag.svg');
}

.icon-money-bag-light {
  mask-image: url('../../assets/icons/icon_money_bag_light.svg');
}

.icon-check-noborder-rounded {
  border-radius: var(--bdr-4) !important;
}

.icon-check-noborder-mask {
  mask-image: url('../../assets/icons/icon_haken_noborder.svg');
}

.icon-check {
  mask-image: url('../../assets/icons/icon_haken.svg');
}

.icon-x-noborder-mask {
  mask-image: url('../../assets/icons/icon_x_full.svg');
}

.icon-x-noborder {
  background-image: url('../../assets/icons/icon_x_full.svg');
}

.icon-calendar {
  mask-image: url('../../assets/icons/icon_calendar.svg');
}

.icon-alert {
  mask-image: url('../../assets/icons/icon_alert.svg');
  mask-position: center;
}

.icon-key {
  mask-image: url('../../assets/icons/icon_key.svg');
}

.icon-archive {
  mask-image: url('../../assets/icons/icon_archive.svg');
}

.icon-credit-card {
  mask-image: url('../../assets/icons/icon_credit_card.svg');
}

.icon-dashboard {
  mask-image: url('../../assets/icons/icon_dashboard.svg');
}

.icon-customer {
  mask-image: url('../../assets/icons/icon_customer.svg');
}

.icon-anonymous {
  mask-image: url('../../assets/icons/icon_anonymous.svg');
}

.icon-potentials {
  mask-image: url('../../assets/icons/icon_potentials.svg');
}

.icon-traits {
  mask-image: url('../../assets/icons/icon_traits.svg');
  transform: scale(0.95);
}

.icon-goals {
  mask-image: url('../../assets/icons/icon_goals.svg');
  transform: scale(0.95);
}

.icon-globe {
  mask-image: url('../../assets/icons/icon_globe.svg');
}

.icon-download {
  mask-image: url('../../assets/icons/icon_download.svg');
}

.nav-menu .svg-icon {
  background: var(--grey-150);
}

.nav-menu .active .svg-icon {
  background: #fff;
}

.settings-menu {
  .svg-icon {
    background: var(--settings-menu-icon-color);
  }
  .active .svg-icon {
    background: var(--settings-menu-active-icon-color);
  }
  @media screen and (max-width: 600px) {
    flex-direction: row !important;
  }
  @media screen and (max-width: 500px) {
    justify-content: space-between;
    a {
      padding: var(--space-3);
    }
  }
}

.icon-pt {
  mask-image: url('../../assets/icons/icon_pt-kombination.svg');
}

.icon-ellipsis {
  mask-image: url('../../assets/icons/icon_ellipsis.svg');
}

.icon-ellipsis-onboarding {
  background-image: url('../../assets/icons/icon_ellipsis.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: var(--grey-200) !important;
  height: 40px !important;
  border-radius: var(--bdr-2);
}

.icon-coin {
  mask-image: url('../../assets/icons/icon_coin.svg');
}

.icon-coin-light {
  mask-image: url('../../assets/icons/icon_coin_light.svg');
}

.icon-participants {
  mask-image: url('../../assets/icons/icon_participants.svg');
}

.icon-participants-light {
  mask-image: url('../../assets/icons/icon_participants_light.svg');
}

.icon-tests {
  mask-image: url('../../assets/icons/icon_assessments.svg');
}

.icon-csv {
  mask-image: url('../../assets/icons/icon_csv.svg');
}

.icon-pdf {
  mask-image: url('../../assets/icons/icon_pdf.svg');
}

.icon-arrow-down {
  mask-image: url('../../assets/icons/icon_arrow_down.svg');
}

.icon-table-columns {
  mask-image: url('../../assets/icons/icon_table_columns.svg');
}

.icon-filter {
  mask-image: url('../../assets/icons/icon_filter.svg');
}

.icon-file-upload {
  mask-image: url('../../assets/icons/icon_file_upload.svg');
}

.icon-add-pt-fa {
  mask-image: url('../../assets/icons/icon_add_pt_fa.svg');
}

.icon-email {
  mask-image: url('../../assets/icons/icon_email.svg');
}

.icon-email-light {
  mask-image: url('../../assets/icons/icon_email_light.svg');
}

.icon-user-secret {
  mask-image: url('../../assets/icons/icon_user_secret.svg');
}

.icon-user-tag {
  mask-image: url('../../assets/icons/icon_user_tag.svg');
}

.icon-small-arrow {
  mask-image: url('../../assets/icons/icon_small_arrow.svg');
}

.icon-calculator {
  mask-image: url('../../assets/icons/icon_calculator.svg');
}

.icon-eye-open {
  mask-image: url('../../assets/icons/icon_eye_open.svg');
}

.icon-eye-closed {
  mask-image: url('../../assets/icons/icon_eye_closed.svg');
}

.icon-paypal {
  mask-image: url('../../assets/icons/icon_paypal.svg');
}

.icon-invoice {
  mask-image: url('../../assets/icons/icon_invoice.svg');
}

.icon-display {
  mask-image: url('../../assets/icons/icon_display.svg');
}

.icon-sun {
  mask-image: url('../../assets/icons/icon_sun.svg');
}

.icon-moon {
  mask-image: url('../../assets/icons/icon_moon.svg');
}

.icon-search {
  mask-image: url('../../assets/icons/icon_suchfunktion.svg');
}

.icon-link {
  mask-image: url('../../assets/icons/icon_link.svg');
}

.icon-camera {
  mask-image: url('../../assets/icons/icon_camera.svg');
}

.icon-id-card {
  mask-image: url('../../assets/icons/icon_id_card.svg');
}

.icon-sad-face {
  mask-image: url('../../assets/icons/icon_sad_face.svg');
}

.icon-chart {
  mask-image: url('../../assets/icons/icon_chart.svg');
}

.icon-medal {
  mask-image: url('../../assets/icons/icon_medal.svg');
}

.icon-save {
  mask-image: url('../../assets/icons/icon_save.svg');
}

.icon-fingerprint {
  mask-image: url('../../assets/icons/icon_fingerprint.svg');
}

.icon-send {
  mask-image: url('../../assets/icons/icon_send.svg');
}

.icon-elektryon-button {
  mask-image: url('../../assets/icons/icon_elektryon_button.svg');
}

.icon-preview {
  mask-image: url('../../assets/icons/icon_preview.svg');
}

.icon-minus:after {
  mask-image: url('../../assets/icons/icon_minus.svg');
  mask-size: 8px;
  -webkit-mask-position-y: 4px;
}

.icon-check-noborder,
.icon-check-noborder-rounded,
.icon-circle,
.icon-minus {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    width: 16px;
    height: 16px;
    mask-size: 8px;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: var(--checkmark-color);
  }
}

.icon-circle.disabled:after {
  background-color: var(--radio-dot-color-disabled);
}

.icon-circle:after {
  background-color: var(--radio-dot-color);
}

.icon-half-circle {
  mask-image: url('../../assets/icons/icon_half_circle.svg');
}

.icon-check-noborder:after,
.icon-check-noborder-rounded:after {
  mask-image: url('../../assets/icons/icon_haken_noborder.svg');
  mask-size: 16px;
}

.icon-check-noborder-rounded:after {
  mask-size: 40px;
  width: 24px;
  height: 24px;
}

.icon-check-noborder.success-page:after {
  width: 36px;
  height: 36px;
  mask-size: 72px;
}

.icon-check-noborder.large:after {
  mask-size: 28px;
}

.icon-feedback {
  mask-image: url('../../assets/icons/icon_feedback.svg');
}

.icon-feedback-filled {
  mask-image: url('../../assets/icons/icon_feedback_filled.svg');
}

.icon-lightbulb {
  mask-image: url('../../assets/icons/icon_lightbulb.svg');
  transform: scale(1.3);
}

.icon-bug {
  mask-image: url('../../assets/icons/icon_bug.svg');
}

.icon-minus:after {
  mask-image: url('../../assets/icons/icon_minus.svg');
  mask-size: 8px;
  -webkit-mask-position-y: 4px;
}

.icon-circle {
  &:after {
    mask-image: url('../../assets/icons/icon_circle.svg');
  }
  &.large:after {
    mask-size: 16px;
  }
}

.svg-icon.modal {
  background-color: var(--modal-icon-bg-color);
}

.icon-delete.modal,
.icon-trash.modal {
  background-color: var(--warning-bg-color);
}

.icon-pen.custom-input:hover {
  cursor: pointer;
}

.perseo-logo {
  mask-image: url('../../assets/perseo_logo.svg');
  background-color: var(--grey-150);
  &.nav-icon {
    mask-size: 100px;
    mask-position: center;
    width: 100%;
    height: 100%;
    transform: scale(0.9);
  }
}

.perseo-logo.login-logo {
  mask-repeat: no-repeat;
  width: 120px;
  height: 50px;
  mask-size: contain;
  background-color: var(--perseo-logo-bg-color);
}

.perseo-logo-small {
  display: none;
  mask-image: url('../../assets/perseo_logo_wings.svg');
  background-color: var(--grey-150);
  width: 100%;
  height: 100%;
  mask-size: initial;
  mask-position: center;
}

@media screen and (max-width: 768px) {
  .perseo-logo {
    display: none;
  }
  .perseo-logo-small {
    display: block;
  }
}
