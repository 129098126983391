.notification-modal {
  h3 {
    text-align: center;
  }

  p {
    margin-top: 0;
  }

  &.welcome-modal .notification {
    height: auto;
    margin-bottom: var(--space-3);
    padding: var(--space-6);
    h3 {
      font-size: var(--fs-8);
    }
    .svg-icon {
      display: inline-block;
      mask-size: contain;
    }
    p {
      hyphens: none;
    }
  }

  .notification {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin-top: 30px;
    height: 235px;
    overflow: auto;

    &.onboarding {
      margin-bottom: var(--space-7);
      .svg-icon {
        width: 50px;
        height: 50px;
      }
      h3 {
        font-size: var(--fs-8);
        margin-bottom: var(--space-6);
      }
    }

    .svg-icon {
      width: 60px;
      height: 60px;
      background-color: var(--notification-icon-color);
      color: var(--notification-icon-color);
      mask-position: center;
    }

    p {
      text-align: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
