.react-datepicker__tab-loop {
  *,
  *:after,
  *:before {
    box-sizing: content-box;
    color: var(--text-color-primary);
  }
}

.react-datepicker {
  font-family: unset;
  border: none;
  box-shadow: var(--sh-dropdown);
  background-color: var(--datepicker-body-bg-color);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--datepicker-selected-color);
  color: var(--grey-150);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: var(--datepicker-selected-color) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--datepicker-hover-color);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__time-list-item--selected:hover {
  background-color: var(--datepicker-selected-color) !important;
}

.react-datepicker-wrapper {
  width: 100%;
  cursor: pointer;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 4px;
}

.react-datepicker-wrapper input {
  border: 1px solid var(--border-grey);
  &:focus {
    border: 1px solid var(--color-focus-outline);
  }
  width: -webkit-fill-available;
  padding: var(--space-3);
  caret-color: transparent;
  cursor: pointer;
  transition-duration: var(--hover-duration);
}

.datepicker-wrapper.filter {
  .react-datepicker__input-container::before {
    display: none;
  }
  .react-datepicker__input-container::after {
    display: none;
  }
  input {
    height: 32px;
    width: 100%;
    background-color: transparent;
    border: transparent;
  }
}

.react-datepicker-popper {
  z-index: 999 !important;
  width: 305px;
  margin-bottom: 0 !important;
}

.has-value .react-datepicker__input-container::before {
  content: '';
  width: 1px;
  right: 33px;
  top: 9px;
  bottom: 9px;
  position: absolute;
  background-color: var(--small-action-icon-color);
  pointer-events: none;
}

.react-datepicker__input-container {
  cursor: pointer;
  transition-duration: var(--hover-duration);
  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    display: block;
    background-color: var(--small-action-icon-color);
    mask-image: url('../../assets/icons/icon_calendar.svg') !important;
    mask-repeat: no-repeat;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    transition-duration: var(--hover-duration);
  }
  &:hover:after {
    background-color: var(--small-action-icon-hover-color) !important;
    transition-duration: var(--hover-duration);
  }
}

.react-datepicker__close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 39px !important;
  height: 16px !important;
  width: 16px !important;
  background-color: var(--small-action-icon-color) !important;
  &:hover {
    background-color: var(--small-action-icon-hover-color) !important;
  }
  mask-image: url('../../assets/icons/icon_x_full.svg') !important;
  mask-repeat: no-repeat;
  transition-duration: var(--hover-duration);
  display: none !important;
}

.has-value + .react-datepicker__close-icon {
  display: block !important;
  &:before {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: var(--small-action-icon-color);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    display: block;
    content: '';
  }
}

.react-datepicker__close-icon::after {
  display: none !important;
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 60px;
  background-color: var(--datepicker-body-bg-color);
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 60px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: var(--datepicker-hover-color);
}

.react-datepicker__navigation {
  top: -3px;
}

.react-datepicker__header {
  height: 40px;
  background-color: var(--datepicker-header-bg-color);
}

.react-datepicker__header.react-datepicker__header--time {
  height: 40px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: inherit;
  font-weight: var(--fw-3);
}

@media (max-width: 600px) {
  .react-datepicker__day {
    margin: 0.09rem;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 24px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  content: none;
}
