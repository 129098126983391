.user-manual-content {
  max-width: 70ch;
  text-justify: inter-word;
  padding-bottom: 200px;

  h2 {
    font-size: var(--fs-9);
    line-height: var(--lh-5);
  }
  p {
    line-height: 28px;
  }
}

.user-manual-content,
.modal .help {
  h2:not(:first-child) {
    margin-top: 80px;
  }

  h3 {
    line-height: var(--lh-4);
  }

  ul,
  ol {
    margin-left: var(--space-5);
    padding: 0 var(--space-5);
    margin-top: var(--space-5);
    margin-bottom: var(--space-5);
    &.wizard {
      margin-top: var(--space-7);
      padding: 0;
      ul {
        margin-left: 0;
      }
    }
  }

  .textbox {
    padding: var(--space-5);
    border-radius: var(--bdr-2);
    text-align: left;
    background-color: var(--box-background);
    margin: var(--space-5) 0;

    &.important {
      background-color: var(--textbox-important-bg-color);
    }

    *:first-child {
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }

    ul {
      padding-left: var(--space-3);
    }
  }

  h3,
  h4,
  h5 {
    margin-top: var(--space-7);
  }

  h5 {
    font-size: var(--fs-4);
    margin-bottom: var(--space-5);
    line-height: var(--lh-3);
    font-style: italic;
  }

  li {
    font-size: var(--fs-3);
    line-height: 28px;
    text-align: left;
  }

  .table-container {
    margin: -2px;
    border-radius: var(--bdr-2);
    margin: var(--space-5) 0;
  }

  tbody {
    vertical-align: top;
  }

  table {
    background-color: transparent;
    white-space: initial;
    text-align: left;
    td:first-child,
    th:first-child {
      padding: var(--space-3) var(--space-4);
    }
  }

  .credit-pricing * {
    text-align: right;
  }

  th,
  td {
    padding: var(--space-3) var(--space-4);
    line-height: var(--lh-2);
  }

  table th {
    position: unset;
    background-color: var(--user-manual-table-header-bg-color);
  }

  table tr:nth-child(odd) td {
    background-color: var(--header-bg-color);
  }

  table tr:nth-child(even) td {
    background-color: var(--user-manual-table-stripes-bg-color);
  }

  .user-manual-image {
    margin: var(--space-6) 0;
    img {
      width: 100%;
      border: 1px solid var(--border-grey);
      border-radius: var(--bdr-2);
    }
    .caption {
      font-style: italic;
      color: var(--text-color-secondary);
      font-size: var(--fs-2);
    }
  }
}

.wizard .time-line-entry {
  padding-bottom: var(--space-5);
}

.modal .help {
  p,
  li {
    font-size: var(--fs-3);
  }
}
