h1 {
  margin: 0 0 var(--space-5) 0;
  color: var(--color-h1);
  font-weight: 500;
  font-size: var(--fs-8);
  line-height: var(--lh-5);
  margin-bottom: var(--space-7);
  width: 100%;
  &.in-app {
    margin: 0;
    font-size: var(--fs-3);
    line-height: var(--lh-1);
    font-weight: 600;
    color: var(--gey-800);
    padding: 0;
    border: none;
    width: auto;
  }
}

.bulk-edit-sidebar {
  h2 {
    font-size: var(--fs-7);
    line-height: var(--lh-4);
    padding-bottom: var(--space-5);
    margin-bottom: 0;
    @media screen and (max-width: 600px) {
      font-size: var(--fs-6);
      line-height: var(--lh-1);
      padding: 0;
    }
  }
  h3 {
    font-size: var(--fs-4);
    line-height: var(--lh-1);
    margin: var(--space-5) 0;
  }
}

h2 {
  font-size: var(--fs-8);
  line-height: var(--lh-4);
  border: none;
  font-weight: 600;
  color: var(--color-headline);
  box-shadow: none;
  padding: 0;
  margin-bottom: var(--space-8);
  @media screen and (max-width: 600px) {
    font-size: var(--fs-7);
    margin-bottom: var(--space-5);
  }
}

.modal h2 {
  margin-bottom: calc(var(--space-4) - 2px);
  font-size: var(--fs-6);
}

.modal h3 {
  font-size: var(--fs-4);
}

.alert-modal h2 {
  font-size: var(--fs-7);
  text-align: center;
  margin-bottom: var(--space-6);
}

.wizard-overview h3 {
  margin-top: 0;
  font-size: var(--fs-5);
  margin-bottom: var(--space-5);
}

h3,
h4 {
  font-weight: 600;
  color: var(--color-headline);
}

h3 {
  font-size: var(--fs-6);
  margin-top: var(--space-6);
  margin-bottom: var(--space-6);
  line-height: var(--lh-3);
  @media screen and (max-width: 600px) {
    font-size: var(--fs-6);
  }
}

h4 {
  font-size: var(--fs-4);
  margin-bottom: var(--space-5);
  line-height: var(--lh-3);
}

h5 {
  color: var(--color-headline);
  font-weight: 500;
}

p {
  line-height: var(--lh-3);
  margin-bottom: var(--space-5);
  max-width: 70ch;
  font-size: var(--fs-4);
  color: var(--text-color-primary);
}

p.small {
  font-size: var(--fs-3);
}

#login-container p {
  font-size: var(--fs-3);
  line-height: var(--lh-3);
  margin-bottom: var(--space-7);
}

.text-secondary {
  font-size: var(--fs-2);
  color: var(--text-color-secondary);
  line-height: var(--lh-2);
  font-weight: var(--fw-2);
}

.uppercase-label {
  color: var(--text-color-secondary);
  font-size: var(--fs-0);
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-weight: 600;
}

.legal {
  p {
    line-height: var(--lh-3);
    font-size: var(--fs-3);
  }
  h3 {
    font-size: var(--fs-4);
    margin-top: var(--space-7);
    margin-bottom: var(--space-5);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &:focus {
    box-shadow: none !important;
  }
}

em {
  hyphens: none;
  -webkit-hyphens: none;
}
