*,
*::before,
*::after {
  box-sizing: border-box;
  line-height: var(--lh-1);
}
* {
  margin: 0;
}

.light-dark-transition {
  --duration: 500ms;
  *,
  *::before,
  *::after {
    transition: color var(--duration), background-color var(--duration), box-shadow var(--duration),
      border-color var(--duration) !important;
  }
  .scroll-container:after {
    opacity: 0;
  }
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', Helvetica, Sans-Serif;
  background-color: var(--main-bg-color);
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: var(--text-color-primary);
}

#root:after {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(200, 8px);
  grid-template-rows: repeat(200, 8px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: -1;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#root {
  height: 100%;
}

.header-content-container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - var(--main-nav-width));
}

@media screen and (max-width: 768px) {
  h1.in-app {
    font-size: var(--fs-3) !important;
  }
}

@media screen and (max-width: 600px) {
  h1.in-app {
    display: none;
  }
}

main {
  flex-grow: 1;
  font-size: var(--fs-3);
  position: relative;
  height: calc(100% - var(--header-height));
  display: flex;
  background-color: var(--main-bg-color);
  @media screen and (max-width: 600px) {
    &.settings-nav-visible {
      flex-direction: column;
    }
  }
}

/* hide arrows in number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input.has-error,
textarea.has-error,
div.has-error,
.show-error .rsw-ce {
  border: 1px solid var(--input-error-border-color) !important;
  background-color: var(--input-error-bg-color) !important;
}

.navbar-tooltip {
  display: none !important;
}

.table-button-tooltip {
  &::before {
    z-index: -1 !important;
  }
  &::after {
    left: 90% !important;
  }
}

@media screen and (max-width: 768px) {
  .navbar-tooltip {
    display: block !important;
  }
}

@media (hover: none) {
  .navbar-tooltip {
    display: none !important;
  }
}

.downloads thead {
  display: none;
}

.downloads td:first-child {
  width: 75%;
}

.downloads td:last-child {
  width: 25%;
  text-align: center;
}

.textlink {
  font-weight: 500;
  &.light {
    font-weight: 400;
  }
  font-size: inherit;
  text-decoration: none;
  color: var(--link-color);
  background-color: transparent;
  border: none;
  padding: 0;
  border-bottom-color: var(--link-color);
  border-bottom-style: dashed;
  border-bottom-width: 1px;
}

.textlink:hover {
  cursor: pointer;
}

textarea,
input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'] {
  font-family: 'Open Sans';
  border: 1px solid var(--border-grey);
  background-color: var(--input-bg-color);
  border-radius: var(--bdr-2);
  padding: var(--space-3);
  line-height: 18px;
  font-size: inherit;
  min-width: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: var(--text-color-primary);
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'] {
  line-height: 1;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.logged-out-area input {
  background-color: var(--login-input-bg-color);
  height: 40px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

textarea:disabled,
input[type='text']:disabled,
input[type='number']:disabled,
input[type='password']:disabled,
input[type='email']:disabled {
  background-color: var(--box-background);
  color: var(--text-color-secondary);
}

:focus {
  outline: none !important;
}

input:focus,
textarea:focus {
  border: 1px solid var(--color-focus-outline);
  box-shadow: none !important;
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

.using-keyboard {
  .custom-checkbox,
  .toggle-switch-label {
    transition: box-shadow 0s !important;
    -webkit-transition: box-shadow 0s !important;
    &:focus {
      box-shadow: var(--sh-outline) !important;
    }
  }
  :focus {
    transition: box-shadow 0s !important;
    -webkit-transition: box-shadow 0s !important;
    box-shadow: var(--sh-outline);
  }
  .match-point-select:focus {
    box-shadow: inset 0 0 0px 2px var(--color-focus-outline);
  }
}

button:disabled {
  pointer-events: none;
  color: var(--text-color-secondary);
}

button {
  user-select: none;
  font-family: 'Open Sans';
  font-size: inherit;
  margin: 0;
  border: none;
  transition: background-color var(--hover-duration), color var(--hover-duration);
  color: inherit;
}

button,
.no-touch,
.row-actions-toggle-button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button:hover {
  transition: background-color var(--hover-duration);
  cursor: pointer;
}

.tooltip-absolute .__react_component_tooltip {
  position: absolute;
}

.editor-tooltip .__react_component_tooltip {
  white-space: normal;
  text-align: left;
  padding: 8px 16px;
}

.__react_component_tooltip.navbar-tooltip,
.__react_component_tooltip.show.navbar-tooltip {
  transition: opacity 0s !important;
}

.__react_component_tooltip {
  transition: opacity 0.3s ease-in-out !important;
  opacity: 0 !important;
  visibility: visible;
  z-index: 9999 !important;
}

.__react_component_tooltip.show {
  visibility: visible;
  opacity: 1 !important;
  transition: opacity 0.3s ease-in-out !important;
  border-radius: var(--bdr-2);
  box-shadow: 1px 3px 10px 0px hsl(0deg 0% 20% / 15%);
  z-index: 9999 !important;
}

.nav-menu,
.settings-menu {
  .__react_component_tooltip.show {
    box-shadow: none;
  }
}

@media (hover: none) {
  .__react_component_tooltip {
    display: none !important;
  }
}

.react-select__menu-notice--no-options {
  font-size: var(--fs-3);
}

.react-select__indicator .svg-icon {
  transition: background-color var(--hover-duration);
}

.react-select__control:hover .svg-icon.icon-caret {
  background-color: var(--small-action-icon-hover-color) !important;
}

.show-calendar .react-select__control {
  border-color: var(--color-focus-outline) !important;
}

.react-select__clear-indicator:hover .svg-icon,
.react-select__dropdown-indicator:hover .svg-icon {
  background: var(--small-action-icon-hover-color) !important;
  transition: background-color var(--hover-duration);
}

header .react-select__control:hover {
  cursor: text;
}

header .react-select__control:after,
header .global-filter-placeholder:after {
  position: absolute;
  height: 22px;
  width: 22px;
  right: 8px;
  mask-image: url('../../assets/icons/icon_suchfunktion.svg');
  content: '';
  background-color: var(--small-action-icon-color);
  mask-position: 0 2px;
  mask-repeat: no-repeat;
}

header .global-filter-placeholder:after {
  top: 4px;
}

header .react-select__control:focus::after,
header .react-select__control:focus-within::after {
  background-color: var(--global-filter-icon-focussed-color);
}

.react-select__menu-portal {
  z-index: 99 !important;
}

.slide-up {
  opacity: 0;
  transition: opacity 500ms, transform 500ms, height 500ms, visibility 500ms;
  height: 0;
  pointer-events: none;
  visibility: hidden;
}

.slide-down {
  opacity: 1;
  transition: opacity 500ms, transform 500ms, height 500ms, visibility 500ms;
  height: 40px !important;
  visibility: visible;
}

.large.slide-down {
  height: 120px !important;
}

.bulk-edit-sidebar .visible,
.bulk-edit-sidebar .invisible {
  margin-top: var(--space-2);
}

.bulk-edit-sidebar .toggle-button.inactive {
  background-color: var(--button-secondary-bg-darker);
  &:hover {
    background-color: var(--button-secondary-hover-darker);
  }
}

.is-invisible {
  display: none !important;
}

.react-pdf__Page__canvas {
  border-radius: var(--bdr-2);
}

.hidden {
  visibility: hidden;
}

.zipcode-city-wrapper {
  display: grid;
  grid-template-columns: 70px 1fr;
  gap: var(--space-3);
  align-items: baseline;
}

.billing-custom-fields-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-3);
}

@media screen and (max-width: 768px) {
  .zipcode-city-wrapper {
    grid-template-columns: 1fr;
  }
}

.status-bar-visible {
  animation-timing-function: ease-in;
  animation: pop-in 3s forwards;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation: pop-in 3s forwards;
}

.is-fading {
  animation-timing-function: ease-in;
  animation: pop-out 0.5s forwards;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation: pop-out 0.5s forwards;
}

@keyframes pop-in {
  20%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes pop-in {
  20%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pop-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}

@-webkit-keyframes pop-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}

.filter-highlight {
  background-color: var(--filter-highlight-bg-color);
  border-radius: var(--bdr-1);
  color: var(--filter-highlight-text-color);
}

.pop-enter {
  opacity: 0;
  transform: scale(0.9);
}
.pop-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.pop-exit {
  opacity: 1;
}
.pop-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.pop-enter-active .scroll-container,
.pop-exit-active .scroll-container,
.pop-enter-active .notification,
.pop-exit-active .notification,
.pop-enter-active .table-outer-container,
.pop-exit-active .table-outer-container {
  overflow: hidden;
}

.fade-enter,
.slow-fade-enter {
  opacity: 0;
}
.fade-exit,
.slow-fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity var(--toggle-button-duration);
}
.fade-exit-active {
  opacity: 0;
  transition: opacity var(--toggle-button-duration);
}

.slow-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.slow-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

label:hover {
  cursor: pointer;
}

.portal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.legal-footer.has-overlap {
  position: relative;
  bottom: 0;
  padding: var(--space-4);
}

.__floater {
  z-index: 9999 !important;
  max-width: 450px !important;
}

.react-joyride__overlay {
  z-index: 9999 !important;
}

.double-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .button-label-container button {
    width: 44px;
    justify-content: center;
  }
  .button-label-container:first-child button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  .button-label-container:nth-child(2) button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid var(--double-button-spacer-color);
  }
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 600px) {
  .mobile-only {
    display: block;
  }
  .page-group .tile {
    width: 100%;
  }
  .hide-on-mobile {
    display: none;
  }

  .settings-nav-visible .page-group-outer-container {
    width: auto;
  }
}

.template-placeholder {
  text-transform: uppercase;
  &:before {
    content: '{';
  }
  &:after {
    content: '}';
  }
}

.capitalizeFirstLetter {
  display: inline-block;
  &:first-letter {
    text-transform: capitalize;
  }
}

input::-webkit-caps-lock-indicator {
  content: none;
}

.no-options-message {
  color: var(--text-color-secondary);
  padding: 8px 12px;
  font-size: 13px;
}

li.archived {
  display: none;
  color: var(--text-color-secondary);
}

.show-archived li.archived {
  display: inline-grid;
}

@container settings-container (max-width: 500px) {
  .settings-container {
    .page-indicator-container {
      display: none;
    }
    .page-group-footer {
      grid-template-columns: unset;
    }
    .option-entry {
      grid-template-columns: 1fr;
    }
    .form-label {
      grid-column: unset;
    }
    .option-description-container {
      grid-template-columns: 1fr;
      font-size: var(--fs-2);
      line-height: var(--lh-2);
      margin-top: var(--space-2) !important;
      .option-description-content {
        line-height: var(--lh-2);
      }
    }
    .icon-info {
      display: none;
    }
  }
}

@container settings-container (max-width: 600px) {
  .matching-norm-editor-table {
    .legend-scale-container {
      display: none;
    }
    tr {
      vertical-align: top;
    }
    th:nth-child(2) {
      width: 100px;
    }
    button {
      height: 36px;
    }
    .matching-norm-scale-container {
      grid-template-columns: unset;
      > div {
        height: 24px;
      }
    }
  }
}

@container settings-container (max-width: 400px) {
  .options-container {
    grid-template-columns: 1fr !important;
    .options-inner-container {
      flex-direction: row;
      max-width: unset;
      gap: var(--space-3);
    }
    .custom-checkmark {
      margin-top: 0 !important;
      width: var(--space-6);
      height: var(--space-6);
      flex-shrink: 0;
    }
    .checkbox-element {
      grid-template-columns: auto 1fr;
      align-items: center;
      padding: var(--space-3) var(--space-4) !important;
      justify-items: flex-start !important;
      &.language-select-checkbox {
        align-items: baseline;
      }
      .svg-icon {
        width: var(--space-5);
        height: var(--space-5);
      }
      .language-option-title {
        font-size: var(--fs-5);
      }
    }
  }
}

@container editor-container (max-width: 600px) {
  .email-preview-container,
  .email-template-container {
    grid-template-columns: unset !important;
    overflow: scroll !important;
    > div:first-child {
      grid-row: 2;
    }
    .scroll-container {
      overflow: visible;
      &:last-child {
        padding-bottom: 0 !important;
      }
    }
  }
  .email-editor-controls {
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
  }
  .email-editor-logo-container {
    display: none !important;
  }
}

@container page-group-container (max-width: 600px) {
  .email-preview-container,
  .email-template-container {
    grid-template-columns: unset !important;
    overflow: scroll !important;
    > div:first-child {
      grid-row: 2;
    }
    .scroll-container {
      overflow: visible;
      &:last-child {
        padding-bottom: 0 !important;
      }
    }
  }
  .email-editor-controls {
    display: flex !important;
    align-items: flex-start;
    justify-content: space-between;
  }
  .email-editor-logo-container {
    display: none !important;
  }
}

.shake {
  animation: shake 0.2s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px);
  }
  10% {
    transform: translate(-1px, -2px);
  }
  20% {
    transform: translate(-3px, 0px);
  }
  30% {
    transform: translate(3px, 2px);
  }
  40% {
    transform: translate(1px, -1px);
  }
  50% {
    transform: translate(-1px, 2px);
  }
  60% {
    transform: translate(-3px, 1px);
  }
  70% {
    transform: translate(3px, 1px);
  }
  80% {
    transform: translate(-1px, -1px);
  }
  90% {
    transform: translate(1px, 2px);
  }
  100% {
    transform: translate(1px, -2px);
  }
}

.settings-container .email-html-editor {
  margin-bottom: 5px;
}

.mail-preview .scroll-container {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0 -5px;
  width: auto;
}
