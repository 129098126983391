.table-of-contents {
  span {
    line-height: var(--lh-2);
  }
  li {
    padding-top: var(--space-2);
    padding-bottom: var(--space-2);
    padding-right: var(--space-7);
  }

  .toc-headline {
    padding: var(--space-7);
    width: auto;
    margin-bottom: 0;
  }

  .toc-entry {
    &.level-1 {
      font-weight: 600;
    }

    &.level-1:not(:first-child) {
      margin-top: var(--space-4);
    }

    &.level-1,
    &.level-2 {
      padding-left: var(--space-7);
      font-size: var(--fs-3);
    }

    &.level-3 {
      padding-left: 55px;
      font-size: var(--fs-2);
    }
    &.level-1 .chapter-no,
    &.level-2 .chapter-no {
      width: 25px;
      flex-shrink: 0;
    }
    &.level-3 .chapter-no {
      width: 33px;
      flex-shrink: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .toc-headline,
    .toc-entry.level-2,
    .toc-entry.level-3 {
      display: none;
    }
  }
}
