table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  background-color: var(--table-bg-color);
  white-space: nowrap;

  &.has-subrows tbody tr:not(.subrow) {
    cursor: pointer;
  }

  &.has-subrows tr:hover .svg-icon.icon-caret {
    background-color: var(--small-action-icon-hover-color) !important;
  }

  &.configs,
  &.test-select-for-reminder,
  &.participant-select-for-email {
    cursor: pointer;
  }

  &.liveData th:last-child,
  &.currentResults th:last-child,
  &.creditBookings th:last-child {
    width: 100%;
  }

  input[type='checkbox'] {
    margin: 0;
    display: none;
  }

  .checkbox-container + .sort-arrow {
    display: none;
  }
}

th {
  height: 24px;
  text-align: left;
  vertical-align: middle;
  background-color: var(--table-th-bg);
  font-weight: var(--fw-2);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: var(--table-border-color);
  user-select: none;
  &::first-letter {
    text-transform: capitalize;
  }
}

.creditInfos th {
  position: static;
}

th,
td {
  padding: var(--space-3) var(--space-4);
  font-size: var(--fs-3);
  transition: background-color var(--hover-duration);
}

th {
  position: relative;
}

td.expanded.actions-col {
  border-bottom-left-radius: var(--bdr-2);
}

.has-action-col-shadow .actions-col {
  box-shadow: -6px 3px 5px 0px rgb(0 0 0 / 15%);
  transition: background-color var(--hover-duration), box-shadow var(--hover-duration);
}

.checkbox-col,
.shadow-col,
.actions-col {
  position: sticky;
  z-index: 1000 !important;
  background-color: var(--table-bg-color);
  transition: background-color var(--hover-duration);
}

.checkbox-col,
.shadow-col {
  left: 0px;
  cursor: initial;
}

.actions-col {
  right: 0px;
  @media screen and (max-width: 600px) {
    position: static;
  }
}

.shadow-col {
  padding: 6px;
}

.checkbox-col:hover + .details-toggle-col .svg-icon.icon-caret {
  background-color: var(--grey-500) !important ;
}

.configs .checkbox-col,
.test-select-for-reminder .checkbox-col,
.participant-select-for-email .checkbox-col {
  cursor: pointer;
}

.participant-select-for-email td * {
  pointer-events: none;
}

th.checkbox-col,
th.actions-col,
th.shadow-col {
  background-color: var(--table-th-bg);
}

.has-left-shadow .checkbox-col,
.has-left-shadow .shadow-col {
  box-shadow: 6px 3px 5px 0px rgb(0 0 0 / 15%);
  transition: background-color var(--hover-duration), box-shadow var(--hover-duration);
}

.column-controller {
  thead tr {
    border-top: none;
  }
  button {
    border-radius: 4px !important;
  }
  user-select: none;
  .drag-handle .svg-icon {
    transform: translateY(1px);
  }
  th,
  td {
    font-size: inherit;
  }
  th {
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: inset 0 -2px 0 var(--table-border-color);
    border-width: 0;
  }
  tr {
    height: 40px;
    border-top: 1px solid var(--table-border-color);
    &:first-child {
      border-top: none !important;
    }
    &:last-child {
      border-bottom: none !important;
    }
  }
  tr:hover {
    cursor: pointer;
  }
  td,
  th {
    &:first-child {
      width: 8px;
      padding-right: 0;
    }
    &:nth-child(2) {
      width: 240px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
    &:last-child {
      width: 90px;
    }
  }
}

@media (hover: hover) {
  .column-controller .col-name:hover + td .custom-checkbox,
  .column-controller .col-checkbox:hover .custom-checkbox {
    box-shadow: var(--custom-checkbox-shadow);
    transition: box-shadow var(--hover-duration);
  }
}

td.expanded {
  background-color: var(--table-hover-bg-color);
}

tr:not(:last-child) td.closed,
.subrow td {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--table-border-color);
}

.subrow > td {
  background-color: var(--table-hover-bg-color);
  padding: var(--space-4);
}

.users .subrow-container {
  margin: 0 var(--space-3);
}

.subrow-container {
  margin-left: 66px;
  margin-right: var(--space-6);
  @media screen and (max-width: 768px) {
    margin-left: var(--space-3);
  }
}

.settings-container tr.hover td {
  background-color: var(--table-in-settings-hover-color);
}

tr {
  height: 48px;

  &.archived td {
    color: var(--text-color-secondary);
  }

  &.subrow {
    background-color: var(--table-hover-bg-color);
    white-space: normal;
  }

  &.selected,
  &.selected td,
  &.selected:hover,
  &.selected:hover td {
    background-color: var(--table-selected-bg-color) !important;
    transition: background-color var(--hover-duration);
  }

  &:hover td,
  &.hover td {
    @media (hover: hover) {
      background-color: var(--table-hover-bg-color);
    }
  }

  &:hover td td,
  &.hover td td {
    background-color: initial;
  }

  .row-actions-toggle-button {
    transition-duration: var(--hover-duration);
  }
}

.spacer-col {
  width: 100%;
  padding: 0;
}

.credit-calculator {
  border: none;
  width: 100%;

  th {
    border-bottom: 1px solid var(--credit-calculator-table-border-color);
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: var(--credit-calculator-col-width);
    }
  }

  td {
    height: 1px;
  }

  th,
  td {
    background-color: var(--box-background);
  }
  tr:hover {
    cursor: initial;
    td {
      background-color: var(--box-background);
    }
  }
  tfoot td {
    border-top: 1px solid var(--credit-calculator-table-border-color);
    font-weight: var(--fw-2);
  }

  @media screen and (max-width: 600px) {
    th,
    td {
      padding: var(--space-3) var(--space-2);
    }
  }
}

.sort-arrow {
  display: inline;
  position: absolute;
  top: 16px;
}

@media (hover: hover) {
  label:hover .custom-checkbox,
  .configs tr:hover .custom-checkbox,
  .test-select-for-reminder tr:hover .custom-checkbox,
  .participant-select-for-email tr:hover .custom-checkbox {
    box-shadow: var(--custom-checkbox-shadow);
    transition: box-shadow var(--hover-duration);
  }
}

.table-checkbox-label {
  display: inline-flex;
  border-radius: var(--bdr-2);
  transform: translateY(1px);
}

.test-select-for-reminder td .table-checkbox-label {
  pointer-events: none;
}

thead tr:hover {
  background-color: none;
}

.table-inner-container {
  overflow: auto;
  border-radius: var(--bdr-2);
}

.table-outer-container {
  position: relative;
  z-index: 0;
  border: 1px solid var(--table-border-color);
  background-color: var(--table-bg-color);
  border-radius: var(--bdr-2);
}

.email-reminder-overview .table-outer-container {
  margin-left: -6px !important;
}

.table-pagination {
  position: absolute;
  right: 0;
  bottom: var(--space-3);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--fs-3);
  &.bottom {
    margin-top: var(--space-3);
    position: relative;
    right: 0;
    bottom: 0;
  }
  .expander-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.settings-container .table-pagination.bottom {
  padding-bottom: var(--space-6);
}

.no-shadow {
  box-shadow: none;
}

.upload-preview th:last-child {
  width: 100%;
}

.statistics table {
  width: 100%;
}

table.downloads {
  white-space: initial;
}

.norm-table,
.bulk-edit-preview-table,
.weight-table {
  pointer-events: none;
  tr:last-child td {
    border-bottom: none !important;
  }
}

.norm-table {
  th,
  td {
    text-align: right;
  }
}

.norm-table,
.matching-norm-table {
  thead tr {
    height: 40px;
  }
  tbody tr {
    height: 32px;
  }

  th,
  td {
    font-size: var(--fs-2);
  }
}

.test-weight-selection {
  background-color: transparent;
  border: none;
  th {
    background-color: transparent;
  }
  tr {
    cursor: default !important;
  }
  tfoot {
    font-weight: var(--fw-2);
    input {
      background-color: transparent !important;
      font-weight: var(--fw-2);
    }
    tr:last-child td {
      border-bottom: none !important;
    }
  }
}

.matching-norm-table {
  table {
    table-layout: auto;
  }
}

td.invalid {
  color: var(--error-message-color);
  text-decoration-line: underline;
  text-decoration-style: wavy;
}

.details-toggle-col {
  padding-left: 0;
  position: relative;
  z-index: 999;
}

.statistics tr {
  height: 40px;
}

table.startup-notifications tr:hover td,
.upload-preview table tr:hover td,
table.creditInfos tr:hover td,
table.downloads tr:hover td,
.dashboard tr:hover td,
.liveData tr:hover td {
  background-color: transparent;
}

table.email-preview tr:hover td,
table.reminders tr:hover td {
  background-color: var(--header-bg-color);
}

.dashboard {
  table td,
  table th {
    border-color: var(--table-border-color-dashboard) !important;
  }
  table.finishedPts {
    width: 100%;
    tr {
      height: 40px;
    }
    td:first-child {
      color: var(--text-color-secondary);
    }
    td:last-child {
      text-align: right;
      font-weight: var(--fw-2);
      font-size: var(--fs-4);
    }
  }

  .liveData .table-inner-container {
    height: 218px;
  }
}

.dashboard,
.user-management,
.test-weight-selection,
.settings-container {
  .table-outer-container,
  table,
  th {
    background-color: transparent;
  }
  .table-outer-container {
    border: none;
    margin: calc(-1 * var(--space-3)) 0;
  }
  th {
    color: var(--text-color-secondary);
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
  .actions-col,
  .checkbox-col,
  .shadow-col {
    background: var(--header-bg-color);
  }
}

table.reminders th:nth-child(2),
table.reminders td:nth-child(2) {
  padding-left: 0;
}

.test-select-for-reminder {
  th:first-child,
  td:first-child {
    padding-left: 4px !important;
  }
}

.user-management,
.startup-notifications,
.user-capabilities-selection,
.add-user-check-input,
.norm-editor,
.matching-norm-editor-table,
.test-select-for-reminder {
  .table-inner-container {
    margin-bottom: var(--space-6);
  }
  tr:hover td {
    background-color: var(--header-bg-color);
  }
  td.expanded {
    background-color: transparent;
  }
  tr.subrow td {
    padding: 0 0 var(--space-4) 0;
    background-color: transparent;
  }
  tr.subrow {
    background-color: transparent;
  }
  .subrow-container {
    margin: 0;
  }
  .tab-group-container {
    box-shadow: none;
    border: 1px solid var(--user-management-table-border-color);
  }
}

.user-capabilities-selection tr.archived,
.add-user-check-input tr.archived {
  display: none;
}

.test-weight-selection th {
  background-color: transparent !important;
}

.notification-settings .table-outer-container {
  max-width: 600px;
}

.tab-group-container {
  .table-outer-container {
    border-color: var(--table-in-tab-border-color);
    background-color: transparent;
  }

  table {
    background-color: transparent;

    th {
      background-color: var(--table-in-tab-th-bg);
    }
    td,
    th {
      border-color: var(--table-in-tab-border-color) !important;
    }
  }
}
