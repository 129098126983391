$screen-sm-max: 768px;
$screen-mobile-max: 600px;

@mixin small {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $screen-mobile-max) {
    @content;
  }
}

:root {
  --inset: var(--space-7);
  @include small {
    --inset: var(--space-5);
  }

  --fs-0: 10px;
  --fs-1: 11px;
  --fs-2: 12px;
  --fs-3: 14px;
  --fs-4: 16px;
  --fs-5: 18px;
  --fs-6: 20px;
  --fs-7: 24px;
  --fs-8: 30px;
  --fs-9: 36px;
  --fs-10: 48px;
  --fs-11: 60px;

  --bdr-1: 2px;
  --bdr-2: 4px;
  --bdr-3: 6px;
  --bdr-4: 8px;
  --bdr-5: 12px;
  --bdr-6: 16px;
  --bdr-7: 24px;
  --bdr-round: 50%;

  --space-1: 2px;
  --space-2: 4px;
  --space-3: 8px;
  --space-4: 12px;
  --space-5: 16px;
  --space-6: 24px;
  --space-7: 32px;
  --space-8: 40px;

  --context-menu-padding: 6px;
  --modal-inset: var(--space-6);

  --icon-1: 16px;
  --icon-2: 24px;
  --icon-3: 40px;

  --lh-1: 16px;
  --lh-2: 20px;
  --lh-3: 24px;
  --lh-4: 32px;
  --lh-5: 40px;

  --grey-50: hsl(208deg 18% 98%);
  --grey-100: hsl(208deg 13% 96%);
  --grey-150: hsl(208deg 10% 92%);
  --grey-200: hsl(208deg 8% 88%);
  --grey-220: hsl(208deg 8% 86%);
  --grey-250: hsl(208deg 8% 83%);
  --grey-300: hsl(208deg 5% 78%);
  --grey-350: hsl(208deg 5% 73%);
  --grey-400: hsl(208deg 5% 68%);
  --grey-500: hsl(208deg 5% 58%);
  --grey-600: hsl(208deg 5% 48%);
  --grey-650: hsl(210deg 5% 43%);
  --grey-700: hsl(225deg 11% 38%);
  --grey-750: hsl(230deg 12% 31%);
  --grey-800: hsl(230deg 10% 27%);
  --grey-820: hsl(230deg 12% 22%);
  --grey-850: hsl(230deg 12% 18%);
  --grey-880: hsl(230deg 12% 16%);
  --grey-900: hsl(230deg 12% 13%);
  --grey-920: hsl(225deg 10% 10%);
  --grey-950: hsl(225deg 10% 8%);

  --primary-100: hsl(270deg 21% 95%);
  --primary-150: hsl(270deg 19% 87%);
  --primary-200: hsl(270deg 16% 81%);
  --primary-300: hsl(270deg 13% 67%);
  --primary-400: hsl(270deg 13% 54%);
  --primary-500: hsl(270deg 13% 40%);
  --primary-600: hsl(270deg 13% 35%);
  --primary-700: hsl(270deg 13% 25%);
  --primary-800: hsl(270deg 16% 15%);
  --primary-900: hsl(270deg 21% 10%);
  --primary-lighter: hsl(271deg 10% 45%);
  --primary-450-saturated: hsl(270deg 18% 47%);
  --primary-500-saturated: hsl(270deg 22% 40%);

  --green-100: hsl(59deg 68% 92%);
  --green-200: hsl(59deg 65% 74%);
  --green-300: hsl(59deg 65% 59%);
  --green-400: hsl(59deg 60% 44%);
  --green-500: hsl(59deg 65% 29%);
  --green-550: hsl(59deg 65% 26%);
  --green-600: hsl(59deg 65% 24%);
  --green-700: hsl(59deg 65% 19%);
  --green-800: hsl(59deg 68% 14%);
  --green-900: hsl(59deg 72% 9%);
  --green-200-matt: hsl(59deg 40% 74%);
  --green-400-matt: hsl(59deg 50% 44%);
  --green-500-matt: hsl(59deg 50% 29%);
  --green-550-matt: hsl(59deg 50% 26%);
  --green-600-matt: hsl(59deg 50% 24%);

  --red-100: hsl(0deg 76% 95%);
  --red-150: hsl(0deg 76% 89%);
  --red-200: hsl(0deg 73% 83%);
  --red-250: hsl(0deg 73% 78%);
  --red-300: hsl(0deg 70% 72%);
  --red-400: hsl(0deg 70% 61%);
  --red-500: hsl(0deg 70% 50%);
  --red-550: hsl(0deg 70% 45%);
  --red-600: hsl(0deg 70% 40%);
  --red-700: hsl(0deg 70% 30%);
  --red-800: hsl(0deg 73% 20%);
  --red-900: hsl(0deg 76% 10%);
  --red-400-matt: hsl(0deg 50% 61%);
  --red-500-matt: hsl(0deg 50% 50%);
  --red-550-matt: hsl(0deg 50% 45%);
  --red-600-matt: hsl(0deg 50% 40%);

  --yellow-100: hsl(46deg 100% 90%);
  --yellow-200: hsl(45deg 100% 81%);
  --yellow-300: hsl(45deg 100% 71%);
  --yellow-400: hsl(45deg 100% 61%);
  --yellow-500: hsl(45deg 100% 51%);
  --yellow-550: hsl(45deg 97% 46%);
  --yellow-600: hsl(45deg 94% 41%);
  --yellow-700: hsl(45deg 95% 31%);
  --yellow-800: hsl(45deg 94% 21%);
  --yellow-900: hsl(46deg 96% 10%);
  --yellow-500-matt: hsl(45deg 70% 51%);

  --perseo-purple: #52215e;

  --fw-1: 400;
  --fw-2: 500;
  --fw-3: 600;

  --modal-small: 400px;
  --modal-medium: 450px;
  --modal-large: 500px;
  --modal-x-large: 600px;

  --header-height: 56px;
  --wizard-max-width: 1000px;

  --bulk-sidebar-width: 248px;
  --bulk-sidebar-mobile-height: 48px;

  --global-filter-width: 240px;
  @include mobile() {
    --global-filter-width: 200px;
  }

  --credit-calculator-col-width: 70px;
  @include mobile() {
    --credit-calculator-col-width: 50px;
  }

  --border-radius-checkbox-element: var(--bdr-2);
  --main-nav-width: 180px;
  --settings-nav-width: 210px;

  @include small {
    --main-nav-width: 56px;
    --settings-nav-width: 40px;
  }
  --letter-spacing-headline: -0.5px;

  --hover-duration: 150ms;
  --toggle-button-duration: 150ms;

  // Shadows

  --sh-1: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px,
    rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  --sh-dropdown: 0px 2px 3px rgba(0, 0, 0, 0.24);
  --sh-input-elements: 0px 0px 2px 0px #554762b3;

  --shadow-default-light: rgb(0 0 0 / 25%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 25%) 0px 0.125em 0.5em,
    rgb(255 255 255 / 10%) 0px 0px 0px 1px inset;
  --shadow-default-dark: rgb(0 0 0 / 25%) 0px 0.0625em 0.0625em, rgb(0 0 0 / 25%) 0px 0.125em 0.5em;

  --sh-outline-light: 0 0 0px 2px var(--focus-outline-inner-border-color), 0px 0px 0px 4px var(--color-focus-outline);
  --sh-outline-dark: 0 0 0px 2px var(--focus-outline-inner-border-color), 0px 0px 0px 4px var(--color-focus-outline);

  --sh-inset-light: inset 0 1px 2px hsl(0deg 0% 80% / 50%);
  --sh-inset-dark: none;

  /* Light Mode Variables */
  --shadow-default: var(--shadow-default-light);
  --sh-outline: var(--sh-outline-light);
  --sh-inset: var(--sh-inset-light);
  --custom-checkbox-shadow: var(--sh-input-elements);

  --text-color-primary: var(--grey-900);
  --text-color-secondary: var(--grey-650);

  --c-tooltip-bg: #5c5067;
  --border-grey: var(--grey-300);
  --border-grey-hover: var(--grey-500);
  --color-focus-outline: var(--primary-500);
  --perseo-logo-bg-color: #58585a;
  --login-container-bg-color: #fff;
  --color-h1: var(--primary-500);

  --login-message-default-bg-color: var(--yellow-200);
  --login-message-warning-bg-color: var(--red-150);
  --login-input-bg-color: #fafafa;

  --lang-select-hover-bg-color: var(--grey-200);
  --progress-bar-bg-color: var(--grey-300);
  --progress-bar-color: var(--green-500);
  --close-button-hover-bg-color: var(--grey-800);
  --error-message-color: var(--red-500);
  --user-management-table-border-color: var(--grey-300);
  --tooltip-text-color: var(--grey-150);

  --modal-bg-color: #4d4d4d80;
  --modal-highlight-text-color: var(--red-500);
  --modal-container-bg-color: var(--main-bg-color);
  --modal-icon-bg-color: var(--green-500);

  --bar-chart-grid-color: var(--grey-300);
  --pie-chart-tooltip-color: #fff;
  --nav-bar-spacer-border-color: #26212ccc;
  --nav-bar-spacer-shadow: #ffffff33;
  --progress-bar-default-color: var(--primary-500);

  --bar-chart-default-color: var(--primary-500);
  --bar-chart-secondary-color: #b5acb9;
  --bar-chart-match-2-color: var(--green-500);
  --bar-chart-match-1-color: var(--yellow-550);
  --bar-chart-match-0-color: var(--red-400);

  --matching-tile-0-color: var(--red-300);
  --matching-tile-1-color: var(--yellow-300);
  --matching-tile-2-color: var(--green-400);

  --timeline-border-color: var(--grey-800);
  --timeline-circle-color: var(--grey-200);

  --main-bg-color: #fafafa;
  --header-bg-color: var(--grey-100);
  --header-border-color: transparent;
  --color-headline: var(--grey-800);

  --icon-color: var(--grey-700);
  --icon-color-hover: var(--grey-750);

  --box-background: var(--grey-150);
  --box-background-hover: var(--grey-200);
  --box-background-warning: var(--red-150);

  --settings-nav-color-active: var(--grey-200);
  --settings-nav-color-hover: #f0f0f0;

  --input-bg-color: #fff;
  --thick-underline-color: var(--grey-200);

  --delete-account-button-bg-color: var(--grey-200);
  --delete-account-button-hover-color: var(--grey-250);

  --settings-container-border-color: var(--grey-300);
  --focus-outline-inner-border-color: #fff;
  --positive-booking-color: var(--green-500);
  --negative-booking-color: var(--red-500);
  --react-select-option-hover-color: var(--grey-150);
  --icon-on-buton-color: var(--grey-800);
  --link-color: var(--perseo-purple);
  --global-filter-icon-focussed-color: var(--primary-500);

  --main-nav-bg-color: var(--primary-600);
  --main-nav-color-active: var(--primary-700);
  --main-nav-hover-color: #00000026;

  --nav-wizard-button-bg-color: #787754;
  --nav-wizard-button-hover-color: #696849;

  --table-bg-color: #fff;
  --table-th-bg: var(--grey-100);
  --table-hover-bg-color: var(--grey-50);
  --table-border-color: var(--grey-200);
  --table-border-color-dashboard: var(--grey-200);
  --table-selected-bg-color: #d3cfd5;
  --table-in-tab-border-color: var(--grey-200);
  --table-in-tab-th-bg: var(--grey-100);

  --table-button-bg-color: var(--grey-150);
  --table-button-bg-color-hover-warning: var(--red-150);
  --table-button-bg-color-hover: var(--grey-200);
  --table-button-darker-bg-color: var(--grey-200);
  --table-button-darker-bg-color-hover: var(--grey-250);

  --add-button-bg-color: var(--green-500);
  --wizard-arrow-bg-color: #756c80;
  --page-indicator-bg-color: var(--grey-200);

  --small-button-bg-color: var(--grey-150);
  --small-button-hover-color: var(--grey-200);

  --grey-button-bg-color: var(--grey-100);
  --grey-button-hover-color: var(--grey-200);
  --grey-button-border-color: var(--grey-300);
  --grey-button-active-color: var(--primary-500);

  --button-disabled-bg-color: #ccc;
  --button-secondary-bg: var(--grey-200);
  --button-secondary-hover: var(--grey-250);
  --button-secondary-bg-darker: var(--grey-250);
  --button-secondary-hover-darker: var(--grey-300);
  --button-tertiary-hover: var(--grey-150);
  --tertiary-button-hover-color: var(--grey-150);
  --tertiary-button-warning-hover-color: hsla(0deg 76% 89% / 50%);
  --button-bg-color: var(--grey-200);
  --button-hover-color: var(--grey-250);
  --button-edit-discard-bg-color: var(--grey-200);
  --button-edit-discard-hover-color: var(--grey-250);
  --toggle-button-active-bg-color: var(--primary-lighter);
  --toggle-button-active-border-color: transparent;

  --dashboard-item-bg-color: #fff;
  --dashboard-pdf-icon-color: var(--grey-700);
  --tabgroup-bg-color: #fff;
  --warning-color: var(--red-500);
  --text-color-highlight: var(--red-500);
  --checkbox-border-color: var(--grey-400);

  --active-checkbox-border-color: var(--primary-500);
  --active-checkbox-bg-color: var(--primary-500);
  --active-checkbox-disabled-bg-color: var(--grey-400);

  --checkbox-bg-color: #fff;
  --radio-dot-color: var(--primary-500);
  --radio-dot-color-disabled: var(--grey-500);
  --checkmark-color: #fff;
  --info-icon-color: var(--primary-500);

  --input-error-bg-color: #fde9e7;
  --input-error-border-color: var(--red-500);

  --tab-active-text-color: var(--primary-700);
  --tab-active-bg-color: var(--grey-200);
  --tab-hover-color: var(--grey-100);
  --tab-text-color: var(--text-color-secondary);

  --settings-nav-active-tab-bg-color: var(--primary-150);
  --settings-nav-tab-hover-color: var(--grey-150);
  --settings-menu-active-icon-color: #111;
  --settings-menu-icon-color: var(--grey-700);

  --main-menu-bg-color: var(--grey-150);
  --main-menu-button-hover-color: var(--grey-150);
  --main-menu-spacer-border-color: #b7b7b7;
  --main-menu-spacer-shadow-color: #fff;
  --main-menu-hover-color: var(--grey-220);

  --active-filter-bg-color: var(--grey-200);
  --filter-menu-color: var(--input-bg-color);

  --row-action-button-icon: var(--grey-600);
  --row-action-button-icon-active: #fff;
  --row-action-button-hover-color: var(--grey-150);
  --row-action-button-active-bg-color: var(--primary-500);

  --status-bar-error-bg-color: var(--red-150);
  --status-bar-error-border-color: var(--red-300);
  --status-bar-success-bg-color: #d3d1b1;
  --status-bar-success-border-color: var(--grey-500);
  --status-bar-neutral-bg-color: var(--grey-150);
  --status-bar-neutral-border-color: var(--grey-500);
  --status-bar-box-shadow-color: #99999980;

  --color-price-info-button: var(--primary-600);
  --test-info-card-bg-color: var(--grey-100);
  --test-info-card-new-flag-color: var(--green-600);
  --traits-color: #919077;
  --goals-color: #808080;
  --potentials-color: var(--primary-500);

  --preview-button-active-bg-color: var(--primary-200);
  --preview-button-hover-bg-color: var(--grey-250);

  --filled-button-checked-bg-color: var(--primary-500);
  --filled-button-checked-border-color: transparent;
  --filled-button-hover-color: var(--grey-200);

  --credit-button-calculated-bg-color: #7a791a4d;
  --credit-button-calculated-hover-color: #7a791a66;
  --credit-button-checked-label-color: var(--primary-200);

  --price-tag-discount-color: var(--red-500);
  --pay-method-icon-active-color: var(--primary-500);
  --success-message-icon-color: var(--primary-500);
  --textbox-important-bg-color: var(--yellow-200);

  --user-manual-table-header-bg-color: var(--grey-200);
  --user-manual-table-stripes-bg-color: var(--grey-150);
  --user-manual-button-border-color: #bfbfbf;
  --user-manual-button-bg-color: var(--box-background);

  --toc-hover-color: var(--grey-200);
  --toc-active-color: var(--grey-300);

  --small-action-icon-color: var(--grey-500);
  --small-action-icon-hover-color: var(--grey-700);
  --small-action-icon-disabled-color: var(--grey-300);

  --navbar-credit-balance-color: var(--primary-200);
  --navbar-credit-balance-label-color: var(--primary-300);

  --toggle-switch-bg-color: var(--grey-250);
  --toggle-switch-active-bg-color: #5c5068b3;
  --toggle-switch-active-border-color: #7d7586;
  --toggle-switch-knob-color: #fff;

  --page-indicator-active-color: var(--primary-500);
  --page-indicator-color: var(--grey-300);

  --primary-bg-color: var(--green-500);
  --primary-bg-hover-color: var(--green-550);

  --warning-bg-color: var(--red-500);
  --warning-bg-hover-color: var(--red-550);

  --datepicker-body-bg-color: var(--input-bg-color);
  --datepicker-header-bg-color: var(--grey-150);
  --datepicker-selected-color: var(--primary-500);
  --datepicker-hover-color: var(--react-select-option-hover-color);

  --filter-highlight-bg-color: #574a644d;
  --filter-highlight-text-color: var(--text-color-primary);

  --notification-icon-color: #756c80;
  --dropzone-hover-bg-color: var(--grey-150);
  --email-preview-bg-color: #fff;
  --credit-calculator-table-border-color: var(--grey-300);
  --filter-builder-bg-color: var(--box-background);
  --menu-warning-bg: hsla(0deg 76% 89% / 70%);
  --norm-preview-background: var(--grey-220);

  --norm-save-as-dialog-bg: #fff;
  --norm-preview-section-border-color: #fff;

  --table-in-settings-hover-color: var(--grey-150);

  --positive-booking-color: var(--green-500);
  --negative-booking-color: var(--red-500);

  --react-select-option-hover-color: var(--grey-150);
  --icon-on-buton-color: var(--grey-800);
  --link-color: var(--perseo-purple);
  --global-filter-icon-focussed-color: var(--primary-500);
  --double-button-spacer-color: var(--grey-button-border-color);

  --elektryon-header-footer-bg: #e9ebed;
  --elektryon-header-footer-border: #c7ccd1;
  --elektryon-h1-color: #5b4365;
  --elektryon-perseo-logo-color: #5f5f5f;
  --elektryon-color-ui-button: #7658844d;

  .settings-container {
    --box-background: var(--grey-150);
    .checkbox-element {
      --box-background-hover: var(--grey-200);
    }
  }

  .bulk-edit-sidebar {
    --button-hover-color: var(--grey-250);
  }

  .page-group .inactive.toggle-button {
    --button-bg-color: var(--grey-200);
    --button-hover-color: var(--grey-250);
  }

  .modal {
    --box-background: var(--grey-150);
    --button-secondary-bg: var(--grey-200);
    --button-secondary-hover: var(--grey-250);
  }

  .menu-button:hover {
    --warning-color: var(--red-500);
  }

  .filter-builder-button-container,
  .subrow-container,
  .modal {
    --button-disabled-bg-color: #ccc;
    --button-edit-discard-bg-color: var(--grey-250);
    --button-edit-discard-hover-color: var(--grey-300);
  }
}

[data-theme='dark'] {
  --text-color-primary: var(--grey-150);
  --text-color-secondary: #a1a6aa;

  --c-tooltip-bg: #5c5067;

  --border-grey: var(--grey-700);
  --border-grey-hover: var(--grey-500);

  --color-focus-outline: var(--green-400);
  --perseo-logo-bg-color: var(--icon-color);
  --login-container-bg-color: var(--grey-820);
  --color-h1: var(--text-color-primary);

  --login-message-default-bg-color: var(--yellow-600);
  --login-message-warning-bg-color: var(--red-500-matt);
  --login-input-bg-color: var(--grey-850);

  --lang-select-hover-bg-color: var(--grey-750);

  --progress-bar-bg-color: var(--grey-800);
  --progress-bar-color: var(--green-400);

  --close-button-hover-bg-color: var(--grey-200);
  --error-message-color: var(--red-300);
  --user-management-table-border-color: transparent;
  --tooltip-text-color: var(--grey-150);

  --modal-bg-color: hsl(230deg 12% 13% / 50%);
  --modal-highlight-text-color: var(--green-400);
  --modal-container-bg-color: var(--login-container-bg-color);
  --modal-icon-bg-color: var(--green-400);

  --bar-chart-grid-color: var(--grey-750);
  --pie-chart-tooltip-color: var(--grey-750);
  --nav-bar-spacer-border-color: var(--main-menu-spacer-border-color);
  --nav-bar-spacer-shadow: var(--main-menu-spacer-shadow-color);
  --progress-bar-default-color: var(--green-400);

  --bar-chart-default-color: hsl(45deg 60% 51%);
  --bar-chart-secondary-color: var(--grey-600);
  --bar-chart-match-2-color: var(--green-500-matt);
  --bar-chart-match-1-color: var(--yellow-600);
  --bar-chart-match-0-color: hsl(0deg 40% 50%);

  --matching-tile-0-color: var(--red-400-matt);
  --matching-tile-1-color: var(--yellow-600);
  --matching-tile-2-color: var(--green-500-matt);

  --timeline-border-color: var(--grey-750);
  --timeline-circle-color: var(--grey-200);

  --main-bg-color: var(--grey-900);
  --header-bg-color: var(--grey-880);
  --header-border-color: var(--grey-800);
  --color-headline: var(--grey-200);

  --icon-color: var(--grey-250);
  --icon-color-hover: var(--grey-150);

  --box-background: var(--grey-850);
  --box-background-hover: var(--grey-850);
  --box-background-warning: hsl(0deg 40% 40%);

  --settings-nav-color-active: var(--grey-820);
  --settings-nav-color-hover: var(--grey-850);

  --input-bg-color: var(--grey-900);
  --thick-underline-color: var(--grey-700);

  --delete-account-button-bg-color: var(--grey-800);
  --delete-account-button-hover-color: var(--grey-750);

  --settings-container-border-color: var(--grey-800);
  --focus-outline-inner-border-color: var(--grey-900);
  --positive-booking-color: var(--green-400);
  --negative-booking-color: var(--red-300);
  --react-select-option-hover-color: var(--grey-750);
  --icon-on-buton-color: var(--grey-100);
  --link-color: initial;
  --global-filter-icon-focussed-color: var(--icon-color);

  --main-nav-bg-color: var(--grey-880);
  --main-nav-color-active: var(--grey-750);
  --main-nav-hover-color: var(--grey-820);

  --nav-wizard-button-bg-color: var(--green-500-matt);
  --nav-wizard-button-hover-color: var(--green-600);

  --table-bg-color: var(--grey-900);
  --table-th-bg: var(--grey-850);
  --table-hover-bg-color: var(--grey-880);
  --table-border-color: var(--grey-800);
  --table-border-color-dashboard: var(--grey-700);
  --table-selected-bg-color: var(--grey-850);
  --table-in-tab-border-color: var(--grey-650);
  --table-in-tab-th-bg: var(--grey-880);

  --add-button-bg-color: var(--green-500-matt);
  --wizard-arrow-bg-color: var(--grey-850);
  --page-indicator-bg-color: var(--grey-750);

  --small-button-bg-color: var(--grey-800);
  --small-button-hover-color: var(--grey-750);

  --grey-button-bg-color: var(--grey-800);
  --grey-button-hover-color: var(--grey-750);
  --grey-button-border-color: transparent;
  --grey-button-active-color: var(--primary-400);

  --button-disabled-bg-color: var(--grey-820);
  --button-secondary-bg: var(--grey-800);
  --button-secondary-hover: var(--grey-750);
  --button-secondary-bg-darker: var(--grey-800);
  --button-secondary-hover-darker: var(--grey-750);
  --button-tertiary-hover: var(--grey-820);
  --tertiary-button-hover-color: var(--grey-750);
  --tertiary-button-warning-hover-color: hsla(0deg 50% 61% / 20%);
  --button-bg-color: var(--grey-800);
  --button-hover-color: var(--grey-800);
  --button-edit-discard-bg-color: var(--grey-700);
  --button-edit-discard-hover-color: var(--grey-750);
  --toggle-button-active-bg-color: var(--grey-850);
  --toggle-button-active-border-color: var(--green-400);

  --dashboard-item-bg-color: var(--grey-850);
  --dashboard-pdf-icon-color: var(--grey-200);

  --tabgroup-bg-color: var(--grey-820);
  --warning-color: var(--red-300);
  --text-color-highlight: var(--yellow-500-matt);
  --checkbox-border-color: var(--grey-700);

  --active-checkbox-border-color: var(--green-400);
  --active-checkbox-bg-color: var(--green-400);
  --active-checkbox-disabled-bg-color: var(--grey-650);
  --checkbox-bg-color: transparent;

  --radio-dot-color: var(--grey-150);
  --radio-dot-color-disabled: var(--grey-500);

  --checkmark-color: var(--input-bg-color);
  --info-icon-color: var(--grey-200);
  --input-error-bg-color: var(--input-bg-color);
  --input-error-border-color: var(--red-300);

  --tab-active-text-color: #fff;
  --tab-active-bg-color: var(--grey-700);
  --tab-hover-color: var(--grey-750);
  --tab-text-color: var(--grey-300);

  --settings-nav-active-tab-bg-color: var(--grey-800);
  --settings-nav-tab-hover-color: var(--grey-820);
  --settings-menu-active-icon-color: var(--text-color-primary);
  --settings-menu-icon-color: var(--grey-300);

  --main-menu-bg-color: var(--grey-820);
  --main-menu-button-hover-color: var(--grey-820);
  --main-menu-spacer-border-color: var(--grey-950);
  --main-menu-spacer-shadow-color: var(--grey-750);
  --main-menu-hover-color: var(--grey-750);

  --active-filter-bg-color: var(--grey-800);
  --filter-menu-color: var(--grey-850);

  --row-action-button-icon: #fff;
  --row-action-button-icon-active: var(--grey-200);
  --row-action-button-hover-color: var(--grey-700);
  --row-action-button-active-bg-color: var(--grey-700);

  --status-bar-error-bg-color: var(--red-500-matt);
  --status-bar-error-border-color: #a14545;
  --status-bar-success-bg-color: var(--green-500-matt);
  --status-bar-success-border-color: var(--green-600);
  --status-bar-neutral-bg-color: var(--grey-850);
  --status-bar-neutral-border-color: var(--grey-750);
  --status-bar-box-shadow-color: #00000033;

  --color-price-info-button: var(--icon-color);
  --test-info-card-bg-color: var(--grey-800);
  --test-info-card-new-flag-color: var(--green-400);
  --traits-color: var(--icon-color);
  --goals-color: var(--icon-color);
  --potentials-color: var(--icon-color);

  --preview-button-active-bg-color: var(--grey-900);
  --preview-button-hover-bg-color: var(--grey-800);

  --filled-button-checked-bg-color: var(--box-background);
  --filled-button-checked-border-color: var(--green-400);
  --filled-button-hover-color: var(--box-background-hover);

  --credit-button-calculated-bg-color: var(--box-background);
  --credit-button-calculated-hover-color: var(--box-background-hover);
  --credit-button-checked-label-color: var(--text-color-secondary);

  --price-tag-discount-color: var(--green-400);
  --pay-method-icon-active-color: var(--icon-color);
  --success-message-icon-color: var(--primary-500-saturated);
  --textbox-important-bg-color: var(--yellow-700);

  --user-manual-table-header-bg-color: var(--grey-820);
  --user-manual-table-stripes-bg-color: var(--grey-850);
  --user-manual-button-border-color: var(--grey-700);
  --user-manual-button-bg-color: var(--grey-750);

  --toc-hover-color: var(--main-nav-hover-color);
  --toc-active-color: var(--main-nav-color-active);

  --small-action-icon-color: var(--grey-500);
  --small-action-icon-hover-color: var(--grey-200);
  --small-action-icon-disabled-color: var(--grey-700);

  --navbar-credit-balance-color: var(--text-color-primary);
  --navbar-credit-balance-label-color: var(--text-color-secondary);

  --toggle-switch-bg-color: var(--input-bg-color);
  --toggle-switch-active-bg-color: var(--primary-400);
  --toggle-switch-active-border-color: var(--border-grey);
  --toggle-switch-knob-color: var(--grey-400);

  --page-indicator-active-color: var(--green-400);
  --page-indicator-color: var(--grey-700);

  --primary-bg-color: var(--green-500-matt);
  --primary-bg-hover-color: var(--green-550-matt);

  --warning-bg-color: var(--red-500-matt);
  --warning-bg-hover-color: var(--red-550-matt);

  --datepicker-body-bg-color: var(--grey-850);
  --datepicker-header-bg-color: var(--grey-700);
  --datepicker-selected-color: var(--green-500);
  --datepicker-hover-color: var(--react-select-option-hover-color);

  --filter-highlight-bg-color: transparent;
  --filter-highlight-text-color: var(--text-color-highlight);

  --notification-icon-color: var(--icon-color);
  --dropzone-hover-bg-color: var(--grey-850);
  --email-preview-bg-color: var(--grey-900);
  --credit-calculator-table-border-color: var(--table-border-color);
  --filter-builder-bg-color: var(--grey-820);
  --menu-warning-bg: hsla(0deg 50% 61% / 20%);
  --norm-preview-background: var(--grey-700);

  --table-button-bg-color: var(--grey-800);
  --table-button-bg-color-hover-warning: var(--menu-warning-bg);
  --table-button-bg-color-hover: var(--grey-750);
  --table-button-darker-bg-color: var(--grey-800);
  --table-button-darker-bg-color-hover: var(--grey-750);

  --norm-save-as-dialog-bg: var(--box-background);
  --norm-preview-section-border-color: var(--grey-900);

  --table-in-settings-hover-color: var(--grey-820);

  --positive-booking-color: var(--green-400);
  --negative-booking-color: var(--red-300);

  --react-select-option-hover-color: var(--grey-750);
  --icon-on-buton-color: var(--grey-100);
  --link-color: initial;
  --global-filter-icon-focussed-color: var(--icon-color);
  --double-button-spacer-color: var(--main-bg-color);

  --elektryon-header-footer-bg: #282e34;
  --elektryon-header-footer-border: #666d78;
  --elektryon-h1-color: #d5dadd;
  --elektryon-perseo-logo-color: #c7ccd1;
  --elektryon-color-ui-button: #6b6a294d;

  .settings-container {
    --box-background: var(--grey-820);
    .checkbox-element {
      --box-background-hover: var(--grey-820);
    }
  }

  .bulk-edit-sidebar {
    --button-hover-color: var(--grey-750);
  }

  .page-group .inactive.toggle-button {
    --button-bg-color: var(--grey-850);
    --button-hover-color: var(--grey-850);
  }

  .modal {
    --box-background: var(--grey-750);
    --button-secondary-bg: var(--grey-750);
    --button-secondary-hover: var(--grey-700);
  }

  .menu-button:hover {
    --warning-color: var(--red-250);
  }

  .filter-builder-button-container,
  .subrow-container,
  .modal {
    --button-disabled-bg-color: var(--grey-800);
    --button-edit-discard-bg-color: var(--grey-700);
    --button-edit-discard-hover-color: var(--grey-750);
  }

  --shadow-default: var(--shadow-default-dark);
  --sh-outline: var(--sh-outline-dark);
  --sh-inset: var(--sh-inset-dark);
  --custom-checkbox-shadow: transparent;
  -webkit-font-smoothing: antialiased;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--text-color-primary);
    -webkit-box-shadow: 0 0 0px 1000px var(--input-bg-color) inset !important;
  }
}
